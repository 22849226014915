.bodyNoScroll {
  overflow: hidden; /* Prevents scrolling on the body */
  height: 100%; /* Limits the height to the current view */
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541); /* Semi-transparent background */
  backdrop-filter: blur(50px); /* Apply blur effect */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* A high value to ensure it's on top */
  overflow: hidden; /* Prevents scrolling within the modal */
  height: 100vh; /* This will make the modal take up the entire height of the viewport */
}
  .modalBackdrop img {
    max-width: 100%; /* Adjust the width as necessary */
    max-height: 80vh; /* Make sure the image height does not exceed 80% of the viewport height */
    object-fit: contain; /* This will ensure that the entire image is visible, scaled down if necessary */
    margin: auto; /* Center the image within the modal */
    display: block;
    position: relative; 

  }

/* Fades in the lightbox content */
/* Slides down the lightbox content */
/* Zooms in the lightbox content */
/* Blurs the background when lightbox is active */
/* Fades in the lightbox content */
/* Modal.module.css */
/* Modal.module.css */
/* Modal.module.css */
.modalContent {
    /* Other styles... */
    opacity: 0.5;
 
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* A high value to ensure it's on top */
   
  }
  
  .modalContentVisible {
    opacity: 1;

  }

  .leftArrow, .rightArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
 
    /* Add more styles as needed */
  }
  
  
  .leftArrow {
    left: 10%;
    color: #d4c7c1;
  }
  
  .rightArrow {
    right: 10%;
    color: #d4c7c1;
  }

  .closeButton {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    background: transparent;
    border: none;
    font-size: 24px; /* Adjust as needed */
    cursor: pointer;
    color: #d4c7c1;; /* Adjust as needed */
  }
  
  @media only screen and (max-width: 768px) {
  .leftArrow, .rightArrow {
    position: absolute; /* Positions the arrows absolutely relative to the nearest positioned ancestor (mainContent) */
  

    font-size: 1.5rem;
}

.leftArrow{
    left: 5%;
}

.rightArrow{
    right: 5%;
}
.modalBackdrop img {
  max-width: 100%; /* On smaller screens, the image can shrink to fit the screen */
}

  }