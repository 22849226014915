/* App.css */
body, html {
    height: 100%;
    margin: 0;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1; /* Ensures content area expands as needed */
    display: flex;
    flex-direction: column;
  }
  