/* src/components/Preloader.module.css */
.preloaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black; /* Adjust background color */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  

  

  
  .progressText {
    margin-top: 10px;
    color: white; /* Adjust text color */
    font-family: 'Azeret-Mono', sans-serif; /* Adjust font family */
  }
  